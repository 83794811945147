const { $api } = useNuxtApp();
export const fetchLanguageLabels = async (language: string) => {
  try {
    const response = await $api("/language-label", {
        method: "POST",
        body: JSON.stringify({ language: language,}),
      });
    return response.language_label; 
  } catch (error) {
    console.error("Error fetching language labels:", error);
    return null;
  }
};
